import React, { useState, useCallback, useEffect } from "react"
import { useDropzone } from "react-dropzone"

import Profile from "./Profile"
import NotificationMessage from "./NotificationMessage"

export default function EditProfile(props) {
	const [imgFile, setImgFile] = useState([])
	const [userInfo, setUserInfo] = useState(props.userInfo)
	const [errors, setErrors] = useState({})
	const [showSuccessMessage, setShowSuccessMessage] = useState(false)

	const onDrop = useCallback((acceptedFiles) => {
		setImgFile(acceptedFiles[0])
		setUserInfo({
			...userInfo,
			avatarUrl: URL.createObjectURL(acceptedFiles[0]),
		})
	})

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		multiple: false,
		accept: { "image/*": [".jpg", ".jpeg", ".png"] },
	})

	function submitUserInfo(e) {
		e.preventDefault()

		const userInfoForm = new FormData()
		if (imgFile.length !== 0) {
			userInfoForm.append("user_info[avatar]", imgFile)
		}
		userInfoForm.append("user_info[id]", userInfo.id)
		userInfoForm.append("user_info[name]", userInfo.name)
		userInfoForm.append("user_info[email]", userInfo.email)
		userInfoForm.append("user_info[caption]", userInfo.caption)
		userInfoForm.append("user_info[contact]", userInfo.contact)

		const url = `/user_infos/${userInfo.id}`
		fetch(url, {
			method: "PATCH",
			headers: {
				Accept: "application/json",
				"X-CSRF-Token": props.csrfToken,
			},
			body: userInfoForm,
		})
			.then((resp) => resp.json())
			.then((data) => {
				if (data.errors !== undefined) {
					setErrors(data.errors)
					setShowSuccessMessage(false)
				} else {
					setShowSuccessMessage(true)
					window.location.href = `/users/${userInfo.id}`
				}
			})
	}

	return (
		<div className="max-w-[960px] mx-auto lg:flex lg:justify-center lg:gap-8">
			<Profile userInfo={userInfo} />

			<div className="w-full lg:max-w-[578px]">
				<div className="font-xs mb-1 text-[#B9B9B9]">แก้ไข</div>
				<section className="p-[18px]">
					<div className="mb-3 lg:mb-[18px]">
						<label htmlFor="name" className="font-xs w-full text-[#818181]">
							ชื่อ
						</label>
						<input
							id="name"
							type="text"
							name="name"
							value={userInfo.name}
							maxLength="50"
							className="font-s w-full h-9 bg-[#FBF7ED] border-0 rounded-md"
							onChange={(e) =>
								setUserInfo({ ...userInfo, name: e.target.value })
							}
						/>
					</div>

					<div className="mb-1.5 lg:mb-[14px]">
						<label htmlFor="caption" className="font-xs w-full text-[#818181]">
							แคปชัน
						</label>
						<textarea
							id="caption"
							name="caption"
							value={userInfo.caption}
							rows="3"
							maxLength="120"
							className="font-s w-full bg-[#FBF7ED] border-0 rounded-md"
							onChange={(e) =>
								setUserInfo({ ...userInfo, caption: e.target.value })
							}
						/>
					</div>

					<div className="mb-1.5 lg:mb-[14px]">
						<label htmlFor="contact" className="font-xs w-full text-[#818181]">
							ช่องทางติดต่อ (เบอร์โทร, Line Id, ...)
						</label>
						<input
							id="contact"
							name="contact"
							value={userInfo.contact}
							maxLength="120"
							className="font-s w-full h-9 px-3 bg-[#FBF7ED] border-0 rounded-md"
							onChange={(e) =>
								setUserInfo({ ...userInfo, contact: e.target.value })
							}
						/>
					</div>

					<div className="mb-5 md:mb-6 lg:mb-8">
						<label htmlFor="email" className="font-xs w-full text-[#818181]">
							อีเมล
						</label>
						<input
							id="email"
							type="email"
							name="email"
							value={userInfo.email}
							maxLength="50"
							className="font-s w-full h-9 bg-[#FBF7ED] border-0 rounded-md"
							onChange={(e) =>
								setUserInfo({ ...userInfo, email: e.target.value })
							}
						/>
					</div>

					<div
						className="w-full mb-14 text-[#6F6F6F] underline decoration-[#6F6F6F] decoration-solid decoration-1 cursor-pointer"
						{...getRootProps()}
					>
						<span className="pr-2 font-s">เปลี่ยนรูปโปรไฟล์</span>
						<i className="fa-solid fa-cloud-arrow-up text-[#6F6F6F]"></i>
						<input {...getInputProps()} />
					</div>

					<div className="flex justify-evenly gap-[6vw] mb-3 md:justify-center md:gap-36 lg:mb-2 lg:gap-28">
						<button className="font-xl py-1.5 px-9 rounded-[22px] bg-[#F4F4F4] text-[#323232]">
							ยกเลิก
						</button>
						<button
							onClick={(e) => submitUserInfo(e)}
							className="dark-btn font-xl py-1.5 px-9"
						>
							บันทึก
						</button>
					</div>

					<NotificationMessage errors={errors} success={showSuccessMessage} />
				</section>
			</div>
		</div>
	)
}
