import React, { useState } from "react"
import { usePopper } from "react-popper"

import { allLabelDescription } from "./nameList"

export default function LabelTooltip({ children, name, className }) {
	const [referenceElement, setReferenceElement] = useState(null)
	const [popperElement, setPopperElement] = useState(null)
	const [arrowElement, setArrowElement] = useState(null)
	const { styles, attributes } = usePopper(referenceElement, popperElement, {
		modifiers: [
			{ name: "arrow", options: { element: arrowElement } },
			{ name: "offset", options: { offset: [5, 12] } },
		],
	})

	function showPopper() {
		popperElement.classList.add("show")
	}

	function hidePopper() {
		popperElement.classList.remove("show")
	}

	function labelDescription(name) {
		const item = allLabelDescription.find((item) => name === item.label)
		return item.description
	}

	return (
		<div
			className={`label-tooltip ${className}`}
			onMouseEnter={() => showPopper()}
			onMouseLeave={() => hidePopper()}
		>
			<div className="label" ref={setReferenceElement}>
				{children}
			</div>
			<div
				className="popper"
				ref={setPopperElement}
				style={styles.popper}
				{...attributes.popper}
			>
				{labelDescription(name)}
				<div
					className="popper-arrow"
					ref={setArrowElement}
					style={styles.arrow}
				/>
			</div>
		</div>
	)
}
