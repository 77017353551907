import React from "react"

import "./duang_result_contact.scss"

export default function ContactInfo({ contact, duangName }) {
	return (
		<div
			id="duang-result-contact"
			className="fixed z-10 w-full text-[#968b5d] flex items-center justify-end bg-black top-[45px] lg:bg-yellow lg:max-w-none lg:left-0"
		>
			<div className="w-full max-w-[960px] mx-auto justify-end items-center lg:flex lg:h-[31px]">
				ดวงของคุณ <em>{duangName}</em> | {contact.contact}
			</div>
		</div>
	)
}
