import ReactDOM from "react-dom/client"
import React from "react"

import EditProfile from "../../components/EditProfile"

document.addEventListener("turbo:load", function () {
	const editProfilePage = document.getElementById("edit-profile")

	if (editProfilePage !== null) {
		const userInfo = JSON.parse(editProfilePage.dataset.userInfo)
		const csrfToken = editProfilePage.dataset.csrfToken
		const root = ReactDOM.createRoot(editProfilePage)

		if (userInfo.caption === null) {
			userInfo.caption = ""
		}

		root.render(<EditProfile userInfo={userInfo} csrfToken={csrfToken} />)
	}
})
