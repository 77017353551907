import React from "react"

import { duangDateFull } from "../../utils/duang_date"

export default function DuangDate({ birthDate }) {
	return (
		<div className="mb-7 lg:mb-0">
			<div className="font-xs mb-1 text-[#989898] lg:mb-2">
				ผลการคำนวณดวงของวันที่
			</div>
			<section className="p-4 sm:text-xl lg:text-2xl lg:p-0 lg:border-0">
				{duangDateFull(birthDate)}
			</section>
		</div>
	)
}
