import { Controller } from "@hotwired/stimulus"

export default class AdminCreateUserController extends Controller {
	static targets = [
		"inputRole",
		"formulaSelection",
		"roleInputValue",
		"expiresAt",
		"presetExpiresButton",
	]
	static values = { role: String, defaultExpires: String }

	connect() {
		const inputRoleStudent = this.inputRoleTargets.find(
			(t) => t.value === this.roleValue
		)
		inputRoleStudent.checked = true
		this.setRoleInputValue(this.roleValue)

		const defaultPreset = this.presetExpiresButtonTarget
		this.setPresetExpiresOption(defaultPreset)
	}

	chooseRole(event) {
		const roleName = event.currentTarget.value

		this.clearInputRoles()
		this.checkedSelectedRole(event)
		this.setRoleInputValue(roleName)
		this.toggleFormulaSelection(roleName)
	}

	clearInputRoles() {
		const targets = this.inputRoleTargets
		targets.forEach((t) => (t.checked = false))
	}

	checkedSelectedRole(event) {
		event.currentTarget.checked = true
	}

	setRoleInputValue(roleName) {
		this.roleInputValueTarget.value = roleName
	}

	toggleFormulaSelection(roleName) {
		let selectTag = this.formulaSelectionTarget.querySelector("select")

		if (roleName === "student") {
			this.formulaSelectionTarget.setAttribute("style", "display:block")
			selectTag.disabled = false
		} else {
			this.formulaSelectionTarget.setAttribute("style", "display:none")
			selectTag.disabled = true
		}
	}

	choosePresetExpiresAt(event) {
		event.preventDefault()
		this.setPresetExpiresOption(event.target)
	}

	setPresetExpiresOption(presetBtn) {
		const option = presetBtn.dataset.value
		const oneDay = 1000 * 60 * 60 * 24
		const twoWeeks = oneDay * 14
		let d = new Date()

		// prettier-ignore
		switch (option) {
			case "1": d.setTime(d.getTime() + twoWeeks); break;
			case "2": d.setMonth(d.getMonth() + 1); break;
			case "3": d.setMonth(d.getMonth() + 3); break;
			case "4": d.setMonth(d.getMonth() + 6); break;
			case "5": d.setFullYear(d.getFullYear() + 1); break
			default:
		}
		this.expiresAtTarget.value = d.toISOString().split("T")[0]
		this.clearPresetExpiresButtons()
		this.highlightPresetExpiresButton(presetBtn)
	}

	clearPresetExpiresButtons() {
		this.presetExpiresButtonTargets.forEach((btn) =>
			btn.classList.remove("highlight")
		)
	}

	highlightPresetExpiresButton(btn) {
		btn.classList.add("highlight")
	}

	changeExpiresAt() {
		this.clearPresetExpiresButtons()
	}
}
