import React, { useContext } from "react"

import { DataContext } from "../../pages/duang/index"
import { mainDisplayNames, subDisplayNames } from "./nameList"

import "./duang_display_control.scss"

function MainControlInput({ name }) {
	const { mainDisplayControl, setMainDisplayControl } = useContext(DataContext)

	return (
		<div className="radio-field-display">
			<input
				id={`display-${name.en}`}
				type="radio"
				value={name.th}
				className="display-radio"
				checked={mainDisplayControl === name.en ? true : false}
				onChange={() => setMainDisplayControl(name.en)}
			/>
			<label
				htmlFor={`display-${name.en}`}
				key={`radio-${name.en}`}
				className="display-radio"
			>
				{name.th}
			</label>
		</div>
	)
}

function SubControlInput({ name }) {
	const { subDisplayControl, setSubDisplayControl, allowedFeaturesList } =
		useContext(DataContext)

	if (allowedFeaturesList[name.en]) {
		return (
			<div key={`key-${name.en}`} className="radio-field">
				<input
					id={`sub-display-${name.en}`}
					type="radio"
					value={name.th}
					className="sub-display-radio"
					checked={subDisplayControl === name.en ? true : false}
					onChange={() => setSubDisplayControl(name.en)}
				/>
				<label
					htmlFor={`sub-display-${name.en}`}
					key={`radio-sub-${name.en}`}
					className="sub-display-label"
				>
					{name.th}
				</label>
			</div>
		)
	} else {
		return null
	}
}

export default function DuangDisplayControl() {
	const { mainDisplayControl } = useContext(DataContext)
	const subDisplayNameList = Object.values(subDisplayNames[mainDisplayControl])

	return (
		<div className="duang-display-control">
			<div className="flex gap-[10vw] justify-center my-5 md:my-6 lg:my-5 lg:gap-[12%]">
				{mainDisplayNames.map((name) => (
					<MainControlInput key={`ctrl-${name.en}`} name={name} />
				))}
			</div>

			<div className="flex items-center justify-center mx-1 gap-2 sm:gap-4 md:gap-6 mb-7 sm:mx-3 md:mb-8 lg:mb-8">
				{subDisplayNameList.map((name) => (
					<SubControlInput key={`sub-ctrl-${name.en}`} name={name} />
				))}
			</div>
		</div>
	)
}
