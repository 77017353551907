import React, { useContext, useState } from "react"

import { DataContext } from "../../pages/duang/index"
import { dayIndices, monthIndices, yearIndices } from "../../utils/static_list"

export default function DuangForm({ foretell }) {
	const { name, setName, birthDate } = useContext(DataContext)

	const { dayNumber, monthNumber, yearBE } = birthDate
	const [selectedDayNumber, setSelectedDayNumber] = useState(dayNumber)
	const [selectedMonthNumber, setSelectedMonthNumber] = useState(monthNumber)
	const [selectedYear, setSelectedYear] = useState(yearBE)

	function getForetell(e) {
		e.preventDefault()
		foretell(selectedDayNumber, selectedMonthNumber, selectedYear)
	}

	return (
		<div className="w-full duang-form mb-7 lg:mb-0 lg:px-4 lg:pb-6 lg:pt-8 lg:bg-hk9-gray-4">
			<div className="font-xs mb-1 text-[#989898] lg:hidden">ข้อมูลลูกดวง</div>

			<section className="relative p-4 lg:p-0 lg:border-0">
				<div className="w-full lg:max-w-[960px] lg:mx-auto lg:flex lg:justify-center lg:items-end lg:gap-[3%]">
					<div className="w-full mb-3 sm-landscape:mb-4 md:mb-5 lg:inline-block lg:max-w-[315px] lg:mb-0">
						<label htmlFor="duang-name" className="text-sm text-hk9-gray-2">
							ชื่อ
						</label>
						<input
							id="duang-name"
							type="text"
							value={name}
							required
							className="w-full bg-[#FBF7ED] border-0 border-b border-solid border-b-[#b9b9b9]"
							onChange={(e) => {
								setName(e.target.value)
							}}
						/>
					</div>

					<div className="w-full sm-landscape:flex sm-landscape:mb-5 sm-landscape:gap-6 md:mb-6 lg:mb-0 lg:gap-[6%]">
						<div className="w-full mb-3 sm-landscape:mb-0">
							<label
								htmlFor="duang-dayNumber"
								className="text-sm text-hk9-gray-2"
							>
								วันเกิด
							</label>
							<select
								id="duang-dayNumber"
								name="dayNumber"
								value={selectedDayNumber}
								className="w-full bg-[#fdf7ed] border-0 border-b border-solid border-b-[#b9b9b9]"
								onChange={(e) => setSelectedDayNumber(e.target.value)}
							>
								{dayIndices.map((d) => (
									<option key={`dayNumber-${d}`} value={d}>
										{d}
									</option>
								))}
							</select>
						</div>

						<div className="w-full mb-3 sm-landscape:mb-0">
							<label htmlFor="duang-month" className="text-sm text-hk9-gray-2">
								เดือนเกิด
							</label>
							<select
								id="duang-month"
								name="monthNumber"
								value={selectedMonthNumber}
								className="w-full bg-[#fdf7ed] border-0 border-b border-solid border-b-[#b9b9b9]"
								onChange={(e) => setSelectedMonthNumber(e.target.value)}
							>
								{monthIndices.map((m) => (
									<option key={`month-${m.value}`} value={m.value}>
										{m.name}
									</option>
								))}
							</select>
						</div>

						<div className="w-full mb-5 sm-landscape:mb-0">
							<label htmlFor="duang-year" className="text-sm text-hk9-gray-2">
								ปีเกิด
							</label>
							<select
								id="duang-year"
								name="year"
								value={selectedYear}
								className="w-full bg-[#fdf7ed] border-0 border-b border-solid border-b-[#b9b9b9]"
								onChange={(e) => setSelectedYear(e.target.value)}
							>
								{yearIndices.reverse().map((y) => (
									<option key={`year-${y}`} value={y}>
										{y}
									</option>
								))}
							</select>
						</div>
					</div>
					<button onClick={(e) => getForetell(e)}>OK</button>
				</div>
			</section>
		</div>
	)
}
