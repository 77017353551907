import React, { useContext } from "react"

import { DataContext } from "../../pages/duang"
import MansionPosition from "./MansionPosition"

export default function DuangMansion({ data }) {
	const { isSelectedFeatureVisible } = useContext(DataContext)

	if (!isSelectedFeatureVisible) {
		return null
	}
	return (
		<div className="human-template max-w-[630px] sm:w-[310px] sm:h-[230px] md:w-[490px] md:h-[355px] px-1 py-6 mx-auto sm:px-4 sm-landscape:px-6">
			<MansionPosition data={data} />
			<svg
				viewBox="0 0 1100 772"
				xmlns="http://www.w3.org/2000/svg"
				xmlSpace="preserve"
				style={{
					fillRule: "evenodd",
					clipRule: "evenodd",
					strokeLinecap: "round",
					strokeLinejoin: "round",
					strokeMiterlimit: 1.5,
				}}
			>
				<path
					d="M75 825h231.975a8 8 0 0 0 8-8.025l-.95-306a8 8 0 0 0-8-7.975H224a6 6 0 0 1-6-6v-26.383c0-1.051.331-2.076.946-2.928l258.633-358.182a8 8 0 0 1 12.062-1.054L838 447l187.25 13.974a4.994 4.994 0 0 0 4.93-2.937c.86-1.92.43-4.174-1.08-5.639L741.471 173.427a8.005 8.005 0 0 0-3.447-1.971l-221.712-60.995a8 8 0 0 0-8.572 2.981L241.593 476.104a3 3 0 0 0 2.478 4.775l42.451-.849a3 3 0 0 0 2.355-1.221l214.746-291.51a8 8 0 0 1 11.95-1.055L828 483h1l140.379 9.681a5 5 0 0 1 4.656 4.951l1.944 257.646a4.999 4.999 0 0 1-2.254 4.217L807.158 868.953a4 4 0 0 1-6.197-3.322l-1.92-366.743a8 8 0 0 0-7.929-7.957l-325.006-2.86a8.002 8.002 0 0 0-8.07 8.036l.93 208.31a8 8 0 0 0 7.608 7.955l147.122 7.22a8 8 0 0 0 8.392-7.906l1.827-173.644a7.998 7.998 0 0 0-7.957-8.085l-172.864-.914a7.999 7.999 0 0 0-8.042 8.051l1.899 294.317a8 8 0 0 0 7.63 7.94L783 855l136-88V535.703a6 6 0 0 0-5.695-5.993l-46.936-2.386a5.998 5.998 0 0 0-6.305 6.052l2.876 285.684a6 6 0 0 0 6 5.94H1170"
					style={{ fill: "none", stroke: "#000", strokeWidth: "5px" }}
					transform="translate(-72.5 -103.691)"
				/>
			</svg>
		</div>
	)
}
