document.addEventListener("turbo:load", function () {
	const menuBarBtn = document.getElementById("menu-bar-btn")

	if (menuBarBtn != null) {
		const backdropElement = document.getElementById("backdrop")
		const menuPanel = document.getElementById("menu-panel")
		const mainContext = document.querySelector("main")

		menuBarBtn.addEventListener("click", function () {
			menuBarBtn.classList.toggle("opened")

			if (menuPanel.classList.contains("hidden")) {
				menuPanel.classList.replace("hidden", "flex")
				mainContext.classList.add("fix-position")
				backdropElement.classList.remove("invisible")
			} else {
				menuPanel.classList.replace("flex", "hidden")
				mainContext.classList.remove("fix-position")
				backdropElement.classList.add("invisible")
			}
		})

		backdropElement.addEventListener("click", function () {
			menuBarBtn.classList.remove("opened")
			menuPanel.classList.replace("flex", "hidden")
			mainContext.classList.remove("fix-position")
			backdropElement.classList.add("invisible")
		})
	}
})
