const days = new Array(31)
for (let i = 0; i < 31; i++) {
	days[i] = i + 1
}

const months = [
	{ name: "มกราคม", value: 1 },
	{ name: "กุมภาพันธ์", value: 2 },
	{ name: "มีนาคม", value: 3 },
	{ name: "เมษายน", value: 4 },
	{ name: "พฤษภาคม", value: 5 },
	{ name: "มิถุนายน", value: 6 },
	{ name: "กรกฎาคม", value: 7 },
	{ name: "สิงหาคม", value: 8 },
	{ name: "กันยายน", value: 9 },
	{ name: "ตุลาคม", value: 10 },
	{ name: "พฤศจิกายน", value: 11 },
	{ name: "ธันวาคม", value: 12 },
]

const years = []
const yearMax = new Date().getFullYear() + 543
const yearMin = yearMax - 150
for (let i = yearMin; i < yearMax; i++) {
	years[i] = i + 1
}

const dayIndices = days
const monthIndices = months
const yearIndices = years

export { dayIndices, monthIndices, yearIndices }
