document.addEventListener("turbo:load", function () {
	const dashboard = document.getElementById("dashboard")

	if (dashboard != null) {
		const noticeMessage = document.getElementById("notice-msg")

		if (noticeMessage) {
			setTimeout(function () {
				noticeMessage.classList.add("slideup")
			}, 2000)
		}
	}
})
