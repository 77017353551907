import React, { useContext } from "react"

import { DataContext } from "../../pages/duang/index"
import DuangTable from "./DuangTable"
import DuangHuman from "./DuangHuman"
import DuangMansion from "./DuangMansion"

export default function DuangResult({ foretellData }) {
	const { mainDisplayControl, subDisplayControl } = useContext(DataContext)
	const view = mainDisplayControl + "-" + subDisplayControl

	switch (view) {
		case "horakhun-table":
			return <DuangTable data={foretellData?.horakhun?.table || []} />

		case "horakhun-human":
			return <DuangHuman data={foretellData?.horakhun?.human || []} />

		case "horakhun-mansion":
			return <DuangMansion data={foretellData?.horakhun?.mansion || []} />

		case "yarm-table":
			return <DuangTable data={foretellData?.yarm?.table || []} />

		case "yarm-human":
			return <DuangHuman data={foretellData?.yarm?.human || []} />

		case "yarm-mansion":
			return <DuangMansion data={foretellData?.yarm?.mansion || []} />

		default:
			return <div className="p-4">Unknow view</div>
	}
}
