import React, { useContext } from "react"

import { DataContext } from "../../pages/duang"
import BaseRow from "./BaseRow"

import LabelTooltip from "./LabelTooltip"

export default function DuangTable({ data }) {
	const { isSelectedFeatureVisible } = useContext(DataContext)

	if (!isSelectedFeatureVisible) {
		return null
	}

	return (
		<div className="overflow-x-auto lg:overflow-x-clip result-table">
			<div className="row-group-7-cols h-[110px] grid col-span-7 content-center justify-items-center bg-hk9-table-column-1 lg:text-[0.9375rem]">
				<LabelTooltip name="โลกาวินาศ" className="head-column">
					โลกาวินาศ
				</LabelTooltip>
				<LabelTooltip name="อุบาทว์" className="head-column">
					อุบาทว์
				</LabelTooltip>
				<LabelTooltip name="ขุมทรัพย์" className="head-column">
					ขุมทรัพย์
				</LabelTooltip>
				<LabelTooltip name="มรณะ" className="head-column">
					มรณะ
				</LabelTooltip>
				<LabelTooltip name="อธิบดี" className="head-column">
					อธิบดี
				</LabelTooltip>
				<LabelTooltip name="ราชาโชค" className="head-column">
					ราชาโชค
				</LabelTooltip>
				<LabelTooltip name="ธงชัย" className="head-column">
					ธงชัย
				</LabelTooltip>
			</div>

			{Object.entries(data).map(([baseKey, baseValue]) => (
				<BaseRow key={baseKey} baseValue={baseValue} />
			))}
		</div>
	)
}
