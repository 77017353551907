import React from "react"

import LabelTooltip from "./LabelTooltip"

import "./duang_result.scss"

export default function MansionPosition({ data }) {
	return (
		<>
			{data.map((position, indx) => {
				const keyPosition = `mansion-${indx}`
				return (
					<LabelTooltip
						key={keyPosition}
						name={keyPosition}
						className={`position ${keyPosition} sm-landscape:text-xl lg:text-2xl`}
					>
						{position.toUpperCase()}
					</LabelTooltip>
				)
			})}
		</>
	)
}
