import React from "react"

import LabelTooltip from "./LabelTooltip"

export default function BaseRow({ baseValue }) {
	const borderBottom =
		baseValue.label.length != 0 ? "border-b border-b-hk9-gray-3" : ""

	return (
		<div className="justify-start row-group-7-cols grid col-span-7">
			{baseValue.label.map((label) => {
				return (
					<LabelTooltip
						key={`label-${label}`}
						name={label}
						className={`w-full py-2 text-center ${borderBottom}`}
					>
						{label}
					</LabelTooltip>
				)
			})}
			{baseValue.score.map((score) => {
				return (
					<div
						key={`score-${score}`}
						className="w-full py-2 text-center border-b border-solid border-b-hk9-gray-3"
					>
						{score}
					</div>
				)
			})}
		</div>
	)
}
