import React from "react"

export default function NotificationMessage({ errors, success }) {
	return (
		<>
			{Object.keys(errors).length === 0 ? (
				success ? (
					<div className="text-green-400 my-4 p-4 border border-solid border-green-400">
						Successfully update profile
					</div>
				) : (
					""
				)
			) : (
				<div className="text-red-400 my-4 p-4 border border-solid border-red-400">
					{Object.keys(errors).map((errKey) => {
						let errMessages = errors[errKey].join(", ")
						return `${errKey} : ${errMessages}`
					})}
				</div>
			)}
		</>
	)
}
