const mainDisplayNames = [
	{ en: "horakhun", th: "หรคุณ 9 ชั้น" },
	{ en: "yarm", th: "จับยาม" },
]

const subDisplayNames = {
	horakhun: [
		{ en: "table", th: "ดวงกำเนิด" },
		{ en: "human", th: "ตำแหน่งร่างกาย" },
		{ en: "mansion", th: "ตำแหน่งบ้าน" },
	],
	yarm: [
		{ en: "table", th: "ดวงยาม" },
		{ en: "human", th: "ตำแหน่งร่างกาย" },
		{ en: "mansion", th: "ตำแหน่งบ้าน" },
	],
}

// prettier-ignore
const tableRowLabelDescription = [
	{ label: "โลกาวินาศ",  description: "สิ่งที่ทำให้เราเสื่อมเสีย ไม่ดี ความทุกข์ ในทางร้าย" },
	{ label: "อุบาทว์",     description: "สิ่งที่ควร ปรับเปลี่ยนในวันนั้นหรือแก้เคล็ดในวันนั้น มองในทางดิ่งลง" },
	{ label: "ขุมทรัพย์",    description: "ไปทำอะไรเกี่ยวกับทรัพย์ กู้เงิน ยืมเงิน ทวงเงิน", },
	{ label: "มรณะ",      description: "ห้ามให้ฤกษ์ในวันนั้น ถือเป็นวันไม่ดี และให้พิจารณา ฐาน 8,9", },
	{ label: "อธิบดี",      description: "ผลงาน คนชื่นชม การศึกษา สำเร็จ" },
	{ label: "ราชาโชค",   description: "การที่จะทำให้คนนั้นสุขสบาย ให้ทำการในวันนี้ คนที่ให้งาน ให้ของ ให้ความช่วยเหลือ ให้กู้ คือใคร", },
	{ label: "ธงชัย",      description: "จะแข่งขัน จะเปิดร้าน ก็สามาถเปิดได้ วันแห่งชัยชนะ มองในทางดิ่งลง", },
	{ label: "อัตตะ",      description: "ตนเอง ของของตน ความคิดของตน, วันเสาร์, ถ้าเกิดวันพุธเช้า ถ้าไม่ได้เป็น เกย์ตุ๊ดทอมดี้ ก็จะต้องทำงานร่วมกับผู้หญิง, ถ้าเป็นพุธ ๘ ชอบอะไรแปลก ชอบเพศเดียวกัน", },
	{ label: "หินะ",       description: "สิ่งเปรอะเปื้อน มายา การแสดง เลือด น้ำตา น้ำกาม การต่อสู้แย่งชิง ความีชื่อเสียง การเสียชื่อเสียง Sex และการเสียตัว", },
  { label: "ธนัง",       description: "เงิน รายรับ รายจ่าย แก้วแหวน เงินทอง มรดก" },
  { label: "ปิตา",       description: "ปาก พ่อ ผู้ใหญ่ ญาติฝ่ายพ่อ  ญาติฝ่ายชาย ครู อาจารย์ ผู้บังคับบัญชาผู้ชาย" },
  { label: "มาตา",      description: "แม่ ผู้ใหญ่ ญาติฝ่ายแม่ ญาติผู้หญิง ครู อาจารย์ ผู้บังคับบัญชาที่เป็นผู้หญิง" },
  { label: "โภคา",      description: "ทรัพย์สิน ที่ดิน หลักทรัพย์ต่างๆ เอกสารสิทธิต่างๆ" },
  { label: "มิชชิมา",     description: "กลางๆ ไม่ร้ายมากนัก ถ้ามัชชิมาตรงกับ อุบาทว์ มรณะ สิ่งเลวร้ายต่างๆ จะถูกลดลงครึ่งหนึ่งหรืออาจไม่เกิดขึ้นเลย ถ้าตรงกับ สุภะ สิทธิโชค หรือ ลาภะ จะมีความสุข ความสำเร็จ มีชื่อเสียง โชคลาภ เจริญรุ่งเรือง มีชัยชนะ เลื่อนยศ เลื่อนตำแหน่ง" },
  { label: "ตนุ",        description: "ตนเอง ของของตน การกระทำของตน เมษายน, พฤษจิกายน ม.ค. ส.ค  = 2" },
  { label: "กดุมภะ",     description: "การเงิน รายได้ ทรัพย์สิน ความมั่นคง พฤษภาคม ก.พ. ก.ย. = 3" },
  { label: "สหัชชะ",     description: "เพื่อน สังคม ข่าวสาร พี่น้องท้องเดียวกัน การมีส่วนร่วม เดินทางระยะสั้น มิถุนายน มี.ค. ต.ค. = 4" },
  { label: "พันธุ",       description: "ใจ ญาติ วงศ์ตระกูล บ้าน ครอบครัว กิจการ ธันวาคม,กรกฎาคม เม.ย. พ.ย. =5" },
  { label: "ปุตตะ",      description: "ลูก เด็กๆ การเกิดขึ้น สิ่งใหม่ๆ คนรักใหม่ ที่เด็กกว่า มกราคม, สิงหาคม พ.ค. = 6" },
  { label: "อริ",        description: "ศัตรู อุปสรรค คู่แข่ง คุก คดีความ กุมภาพันธ์, กันยายน มิ.ย. = 7" },
  { label: "ปัตนิ",       description: "คู่ครอง เพศตรงข้าม หุ้นส่วน คู่สัญญา คู่คดีความ มีนาคม, ตุลาคม ก.ค. พ.ย. = 1" },
  { label: "มรณะ",      description: "สูญเสีย พลัดพราก คดีอาญา หย่าร้าง ตาย เจ็บไข้ เดินทางไกล ต่างชาติ ต่างภาษา" },
  { label: "สุภะ",       description: "ความสุข ความสำเร็จ ชื่อเสียง ศาสนา การศึกษา ต่างชาติ เดินทางไกล" },
  { label: "กรรมะ",     description: "อาชีพ การงาน การกระทำ กิจกรรม สอบ เรียน แข่งขัน" },
  { label: "ลาภะ",      description: "ที่นั่ง โชคลาภ ความสำเร็จ ทางการงาน ลาภลอย ยศศักดิ์" },
  { label: "พยายะ",     description: "โรคภัย ความลับ โหราศาสตร์ การกระทำที่ไม่เปิดเผย ลึกลับ" },
  { label: "ทาสา",      description: "บริวารชาย ผู้ใต้บังคับบัญชา เพศชาย" },
  { label: "ทาสี",       description: "บริวารหญิง ผู้ใต้บังคับบัญชา ที่เป็นผู้หญิง" },

  { label: "อาตมา",     description: "ความคิดของตน" },
  { label: "_ทาสา",     description: "ข้าทาส คนใช้สอย ช่วยงาน" },
  { label: "สิทธิโชค",    description: "โชคลาภ ความสำเร็จ ชื่อเสียง เกียรติยศ โชคในเร็ววัน" },
  { label: "โภคสมบัติ",   description: "ทรัพย์สิน ที่ดิน หลักทรัพย์ต่างๆ เช่น เอกสารสิทธิต่างๆ" },
  { label: "มหาโจร",    description: "การใช้จ่ายสิ้นเปลือง ฟุ่มเฟือย รีดไถ ปล้น ขี้บ่น จู้จี้ การฉกฉวยโอกาสของเจ้าชะตาด้วยเช่นกัน" },
  { label: "อุบาทว์",     description: "อุบัติเหตุ ชั่วร้าย หย่าร้าง ตาย สิ่งที่เกิดขึ้นโดยไม่คาดฝัน คดีอาญา ต่างชาติ ต่างภาษา และการซิกแซ๊ก เล่ห์กลของเจ้าชะตา" },
  { label: "มหาอุปถัมภ์",  description: "ได้รับการอุปถัมภ์ มีคนช่วยเหลือ สนับสนุน พ่อแม่ผู้ปกครอง" },
  { label: "_อัตตะ",     description: "การกระทำของตน" },
  { label: "สักกะ",      description: "เพื่อนฝูง คนสนิท" },
  { label: "ญาติ",       description: "ญาติ พี่ น้อง พ่อแม่ " },
  { label: "ธะนัง",      description: "สภาพการเงิน" },
  { label: "เคหัง",      description: "บ้าน ที่พักอาศัย การมีครอบครัว มดลูก" },
  { label: "นาวัง",      description: "รถ ยานพาหนะ การเดินทาง คนไกล" },
  { label: "ภิริยัง",      description: "คู่ครอง เพศตรงข้าม หุ้นส่วน คู่สัญญา คู่คดีความ" },
]

// prettier-ignore
const humanLabelDescription = [
  { label: "head-0",       description: "หัว : ความคิด" },
  { label: "head-1",       description: "จมูก : การเลือกคบคน" },
  { label: "head-2",       description: "ปาก : การพูดจา" },
  { label: "leftHand-0",   description: "บ่า : ตำแหน่งหน้าที่การงาน" },
  { label: "leftHand-1",   description: "แขน : ความมั่นคงผู้ร่วมงาน ยังไงอย่างไร" },
  { label: "leftHand-2",   description: "มือ : ชอบทำสิ่งใด" },
  { label: "rightHand-0",  description: "บ่า : สถานะความเป็นอยู่หรือคู่ครอง เป็นแบบใด" },
  { label: "rightHand-1",  description: "แขน : ผลของการงาน เป็นเช่นไร" },
  { label: "rightHand-2",  description: "มือ : ชอบงานแบบใด ผลงานที่เกี่ยวข้อง ประเมินผลงาน" },
  { label: "topBody-0",    description: "คอ : การแต่งตัว" },
  { label: "topBody-1",    description: "ลิ้นปี่ : ความอดทน" },
  { label: "topBody-2",    description: "ท้องใหญ่ : ฐานะ ฐานที่สร้างขึ้น" },
  { label: "bottomBody-0", description: "สะดือ : การงาน ผู้ให้งาน ความอยู่รอดผู้ช่วยเหลือ" },
  { label: "bottomBody-1", description: "ท้องน้อย : ความมั่นคงทางการงาน" },
  { label: "bottomBody-2", description: "เพศ : ความชอบ ว่าชอบเพศใด ถ้าเป็นกาลี จะต้อง หย่าร่าง อาภัพ หาเงินด้วย เพศ หรือมีคู่แล้วต้องตาย แต่ถ้าเป็นศรี ใครมีอะไรด้วย ก็จะติดใจหลงเสน่ห์" },
  { label: "leftLeg-0",    description: "สะโพก : ความอดทนการงาน" },
  { label: "leftLeg-1",    description: "ขา : การเดินทาง ความสะดวกสบาย" },
  { label: "leftLeg-2",    description: "เท้า : เดินทางไปเพื่อการใด" },
  { label: "rightLeg-0",   description: "สะโพก : ความอดทนในการงาน" },
  { label: "rightLeg-1",   description: "ขา : การเดินทางขึ้นอยู่กับอะไร" },
  { label: "rightLeg-2",   description: "เท้า : เดินทางเพื่อการใด เพื่ออะไร" },
]

// prettier-ignore
const mansionLabelDescription = [
	{ label: "mansion-0", description: "คื่อ : ตำแหน่งหน้าที่การงาน ชาย" },
	{ label: "mansion-1", description: "พื้น และ เสา : อยู่กินโดยไม่มีหย่าร้าง ครอบครัวเป็นปึกแผ่น" },
	{ label: "mansion-2", description: "หลังคา : ความอบอุ่น ฐานะ ความมั่นคง ไม่เดือดร้อนใคร เลี้ยงตนเองได้ มีความรับผิดชอบ" },
	{ label: "mansion-3", description: "คื่อคา : ตำแหน่งหน้าที่การงาน หญิง" },
	{ label: "mansion-4", description: "เสา : ฐานะ ชาย" },
	{ label: "mansion-5", description: "คาน อกไก่ : ครอบครัว ความรับผิดชอบเอาใจใส่" },
	{ label: "mansion-6", description: "เสา : ฐานะ หญิง" },
]

const allLabelDescription = [
	...tableRowLabelDescription,
	...humanLabelDescription,
	...mansionLabelDescription,
]

export {
	mainDisplayNames,
	subDisplayNames,
	tableRowLabelDescription,
	humanLabelDescription,
	allLabelDescription,
}
