import React from "react"

import "./duang_info.scss"

export default function DuangInfo({ dateInfo }) {
	return (
		<div className="mb-7 lg:mb-0">
			<div className="font-xs mb-1 text-[#989898] lg:mb-0">
				ค่าที่ใช้ในการคำนวณ
			</div>
			<section className="p-4 lg:p-0 lg:border-0">
				<div className="duang-info lg:w-[198px] lg:divide-y lg:divide-[#d9d9d9]">
					<div className="field">
						<div className="label">วัน</div>
						<div className="font-s">{dateInfo.day_thai}</div>
					</div>
					<div className="field">
						<div className="label">เดือน</div>
						<div className="font-s">{dateInfo.month_thai}</div>
					</div>
					<div className="field">
						<div className="label">ปี</div>
						<div className="font-s">{dateInfo.year_thai}</div>
					</div>
					<div className="field">
						<div className="label">ปีนักษัตร</div>
						<div className="font-s">{dateInfo.zodiac}</div>
					</div>
					<div className="field">
						<div className="label">ยาม</div>
						<div className="font-s">{dateInfo.yarm}</div>
					</div>
					<div className="field">
						<div className="label">{dateInfo.moon_pharse}</div>
						<div className="font-s">{dateInfo.day_of_moon}</div>
					</div>
				</div>
			</section>
		</div>
	)
}
