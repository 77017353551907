import * as Turbo from "@hotwired/turbo"
import { Application } from "@hotwired/stimulus"

// include Stimulus controllers
import HelloController from "../controllers/hello_controller"
import AdminCreateUserController from "../controllers/admin_create_user_controller"

// include locale js
import "../pages/user_infos/edit.jsx"
import "../pages/duang"
import "../pages/_menu"
import "../pages/users"

Turbo.start()

// Stimulus
const application = Application.start()
application.debug = false
window.Stimulus = application
Stimulus.register("hello", HelloController)
Stimulus.register("admin-create-user", AdminCreateUserController)

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
