import React from "react"

import LabelTooltip from "./LabelTooltip"

import "./duang_result.scss"

export default function HumanPosition({ data }) {
	return (
		<>
			{Object.entries(data).map(([position, scores]) => {
				return (
					<div key={position} className={`${position}`}>
						{scores.map((score, indx) => {
							const keyPosition = `${position}-${indx}`
							return (
								<LabelTooltip
									key={keyPosition}
									name={keyPosition}
									className={`position ${keyPosition}`}
								>
									{score}
								</LabelTooltip>
							)
						})}
					</div>
				)
			})}
		</>
	)
}
