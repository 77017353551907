import { createRoot } from "react-dom/client"
import React, { useState, useEffect, useRef } from "react"

import DuangForm from "../../components/duang/DuangForm"
import DuangDate from "../../components/duang/DuangDate"
import DuangInfo from "../../components/duang/DuangInfo"
import DuangResult from "../../components/duang/DuangResult"
import DuangDisplayControl from "../../components/duang/DuangDisplayControl"
import {
	mainDisplayNames,
	subDisplayNames,
} from "../../components/duang/nameList"
import { duangDateFull } from "../../utils/duang_date"

import "../../components/duang/duang_result_wrapper.scss"
import ContactInfo from "../../components/duang/ContactInfo"
import saveIconUrl from "../../entrypoints/images/save.svg"
import deleteIconUrl from "../../entrypoints/images/bin.svg"

export const DataContext = React.createContext()

function Duang(props) {
	const today = new Date()
	const initialBirthDate = {
		dayNumber: today.getDate(),
		monthNumber: today.getMonth() + 1,
		yearBE: today.getFullYear() + 543,
	}
	const initialAllowedFeatures = {
		table: false,
		human: false,
		mansion: false,
	}

	const b =
		props.birthDate === undefined
			? initialBirthDate
			: JSON.parse(props.birthDate)

	const [name, setName] = useState(props.name || "")
	const [birthDate, setBirthDate] = useState(b)
	const [foretellData, setForetellData] = useState({})
	const [mainDisplayControl, setMainDisplayControl] = useState(
		mainDisplayNames[0].en
	)
	const [subDisplayControl, setSubDisplayControl] = useState(
		Object.values(subDisplayNames)[0][0].en
	)
	const [allowedFeaturesList, setAllowedFeaturesList] = useState(
		initialAllowedFeatures
	)

	const isSelectedFeatureVisible = allowedFeaturesList[subDisplayControl]

	const contextValues = {
		name,
		setName,
		birthDate,
		mainDisplayControl,
		subDisplayControl,
		setMainDisplayControl,
		setSubDisplayControl,
		allowedFeaturesList,
		isSelectedFeatureVisible,
	}

	const duangResultRef = useRef()
	const noForetellResult = Object.entries(foretellData).length === 0

	useEffect(() => {
		// Get formula features (Duang table, Human or Mansion) and
		// set appropriate enable state for each features
		requestForAllowedFeatures()

		// Set default foretell
		if (props.birthDate != undefined) {
			foretell(b.dayNumber, b.monthNumber, b.year)
		}
	}, [])

	useEffect(() => {
		if (!noForetellResult) {
			duangResultRef.current.scrollIntoView({ behavior: "auto" })
		}
	}, [noForetellResult])

	function requestForAllowedFeatures() {
		console.log(
			"will make GET request to get the allowed_features list of user_id : " +
				props.userId
		)
		const url = `/users/${props.userId}/features`
		fetch(url, {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		})
			.then((resp) => resp.json())
			.then((data) => {
				setAllowedFeaturesList(data)
				// set subDisplayControl according to the boollean values in
				// the allowed_features list
				const initialSubDisplayControl = Object.entries(data).find(
					(a) => a[1]
				)[0]
				setSubDisplayControl(initialSubDisplayControl)
			})
	}

	function foretell(selectedDayNumber, selectedMonthNumber, selectedYear) {
		const selectedYearCE = selectedYear - 543
		const url = `/duangs/new?duang[birth_date]=${selectedDayNumber}&duang[birth_month]=${selectedMonthNumber}&duang[birth_year]=${selectedYearCE}`
		fetch(url, {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
		})
			.then((resp) => resp.json())
			.then((data) => {
				setBirthDate({
					dayNumber: selectedDayNumber,
					monthNumber: selectedMonthNumber,
					yearBE: selectedYear,
				})
				setForetellData(data)
			})
	}

	function saveDuang(e) {
		e.preventDefault()

		const url = `/duangs`
		const birthYearCE = birthDate.yearBE - 543
		const data = JSON.stringify({
			user_id: props.userId,
			name: name,
			birth_date: birthDate.dayNumber,
			birth_month: birthDate.monthNumber,
			birth_year: birthYearCE,
		})

		fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				"X-CSRF-Token": props.csrfToken,
			},
			body: data,
		})
			.then((resp) => resp.json())
			.then((_) => {
				window.location.replace(`/users/${props.userId}`)
			})
	}

	function deleteDuang(e) {
		const duangDate = duangDateFull(birthDate)
		confirm(`คุณกำลังจะลบดวง ${duangDate} กรุณายืนยันเพื่อทำการลบ`)

		const url = `/duangs/${props.id}`
		fetch(url, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				"X-CSRF-Token": props.csrfToken,
			},
		})
			.then((resp) => resp.json())
			.then((_) => {
				window.location.replace(`/users/${props.userId}`)
			})
	}

	if (noForetellResult && props.newDuang) {
		return (
			<DataContext.Provider value={contextValues}>
				<DuangForm foretell={foretell} />
			</DataContext.Provider>
		)
	} else if (noForetellResult && props.newDuang === undefined) {
		return <div className="text-hk9-gray-2">Loading...</div>
	} else {
		return (
			<DataContext.Provider value={contextValues}>
				<ContactInfo contact={props.contact} duangName={name} />

				<form
					className="lg:mt-0 lg:flex lg:flex-wrap lg:justify-center lg:gap-y-8 lg:gap-x-28 lg:mb-6"
					onSubmit={(e) => saveDuang(e)}
				>
					<DuangForm foretell={foretell} />

					<div ref={duangResultRef} className="duang-result-wrapper">
						<DuangDate birthDate={birthDate} />
						<DuangInfo dateInfo={foretellData?.dateInfo} />

						<div className="mb-7 md:mb-8 lg:mb-0">
							<div className="font-xs mb-1 text-[#989898]">ผลการคำนวณ</div>
							<section className="duang-result">
								<DuangDisplayControl />
								<DuangResult foretellData={foretellData} />
							</section>
						</div>
					</div>

					<div className="w-full flex justify-center gap-[10vw] sm-landscape:gap-[15vw] lg:ml-[315px] lg:gap-12">
						{props.newDuang ? (
							""
						) : (
							<button
								className="flex items-center justify-center gap-2 w-[120px] h-[37px] text-[#323232] font-medium bg-[#F4F4F4] rounded-[22px] md:w-[140px] md:h-[40px]"
								onClick={(e) => deleteDuang(e)}
							>
								ลบดวง
								<img
									width="18px"
									height="18px"
									src={deleteIconUrl}
									alt="delete duang"
								/>
							</button>
						)}
						<button
							type="submit"
							className="flex items-center justify-center gap-2 dark-btn w-[120px] h-[37px] font-medium md:w-[140px] md:h-[40px]"
						>
							บันทึก
							<img
								width="20px"
								height="20px"
								src={saveIconUrl}
								alt="save duang"
							/>
						</button>
					</div>
				</form>
			</DataContext.Provider>
		)
	}
}

document.addEventListener("turbo:load", function () {
	const duangElement = document.getElementById("duang")
	if (duangElement != null) {
		const csrfToken = duangElement.dataset.csrfToken
		const userId = duangElement.dataset.userId
		const id = duangElement.dataset.duangId
		const name = duangElement.dataset.duangName
		const birthDate = duangElement.dataset.birthDate
		const newDuang = duangElement.dataset.newDuang
		const contact = JSON.parse(duangElement.dataset.contact)

		const root = createRoot(duangElement)
		root.render(
			<Duang
				csrfToken={csrfToken}
				userId={userId}
				id={id}
				name={name}
				birthDate={birthDate}
				contact={contact}
				newDuang={newDuang}
			/>
		)
	}
})
