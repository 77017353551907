import React from "react"

import { defaultProfileAvatarUrl } from "../utils/default_images"

export default function Profile({ userInfo }) {
	return (
		<div className="w-full lg:max-w-[350px] lg:py-6">
			<div className="font-xs mb-1 text-[#B9B9B9] lg:hidden">โปรไฟล์</div>
			<section className="flex flex-col items-center mb-6 p-[18px] text-center break-all md:py-6 lg:py-0 lg:px-6 lg:m-0 lg:border-0">
				<img
					src={userInfo.avatarUrl || defaultProfileAvatarUrl}
					alt="User profile"
					className="w-[200px] h-[200px] mb-4 rounded-full bg-[#FBF7ED] object-cover"
				/>
				<div className="font-xl mb-3">{userInfo.email}</div>
				<div className="text-lg font-semibold text-[#383838]">
					{userInfo.name}
				</div>
				<div className="text-sm text-[#989898]">{userInfo.caption}</div>
			</section>
		</div>
	)
}
