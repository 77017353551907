import { monthIndices } from "./static_list"

export function duangDateFull(birthDate) {
	let result = `${birthDate.dayNumber} `
	result += monthIndices.find(
		(m) => m.value === parseInt(birthDate.monthNumber)
	).name
	result += ` ${birthDate.yearBE}`
	return result
}
